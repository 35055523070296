<template>
  <div class="page reserve-locker" :class="`page ${$route.meta.headerType}`">
    <div class="container">
      <div class="page-header">
        <h1 class="title">{{ $__t('보관정보') }}</h1>
        <p class="secondary">{{ $__t('{notice-header}.secondary') }}</p>
        <div class="reserve-info">
          <div class="reserve-info__content">
            <h4 class="content-header">{{ order && order.product_data ? order.product_data.store_name : '' }}</h4>
            <p class="content-body" v-html="usageTimeText" />
          </div>
          <!-- <button type="button" @click="openRef('condition-modal')" class="reserve-info__button">
            {{ $__t('예약변경') }}
          </button> -->
        </div>
      </div>
      <div class="page-body">
        <template v-if="order && order.product_data">
          <ui-form ref="form" @submit="submitForm" @error="scrollToError" :formData="formData">
            <div class="section booker">
              <div class="section-header">
                <h2>{{ $__t('Drop Off by') | capitalizeFirstLetter }}</h2>
              </div>
              <div class="section-body">
                <ui-form-list>
                  <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_name.placeholder"
                      :type="formData.user_name.type"
                      :label="formData.user_name.label"
                      :error="formData.user_name.error"
                      v-model="formData.user_name.value"
                      :required="true"
                    />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <div class="lug-phone">
                      <label>
                        {{ formData.user_phone.label }}
                        <span style="color: red"> * </span>
                        <div class="body">
                          <lug-select
                            :placeholder="formData.user_dial_code.placeholder"
                            :type="formData.user_dial_code.type"
                            :items="formData.user_dial_code.items"
                            v-model="formData.user_dial_code.value"
                          />
                          <lug-text-input
                            :placeholder="formData.user_phone.placeholder"
                            :type="formData.user_phone.type"
                            :inputmode="formData.user_phone.input_mode"
                            v-model="formData.user_phone.value"
                            :mask="formData.user_phone.mask"
                          />
                        </div>
                      </label>
                      <div class="required" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                        {{ formData.user_phone.error }}
                      </div>
                    </div>
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_email.placeholder"
                      :type="formData.user_email.type"
                      :label="formData.user_email.label"
                      :error="formData.user_email.error"
                      v-model="formData.user_email.value"
                      :required="true"
                    />
                  </ui-form-list-item>
                </ui-form-list>
              </div>
            </div>

            <div class="section pay-method">
              <div class="section-header">
                <h2>{{ $__t('결제수단 선택') | capitalizeFirstLetter }}</h2>
              </div>
              <div class="section-body">
                <div class="method-area">
                  <dl
                    v-if="order.promotion && order.promotion.type === 'pass'"
                    @click="$router.push({ name: 'ReservePayMethods', params: { ...$route.params } })"
                  >
                    <dt>{{ $__t('프리패스') + ' ' + order.promotion_pass_code }}</dt>
                    <dd>
                      <button type="button" class="icon">
                        <img src="/img/arrow-right@3x.png" class="arrow" />
                      </button>
                    </dd>
                  </dl>
                  <dl
                    v-else
                    v-button
                    @click="$router.push({ name: 'ReservePayMethods', params: { ...$route.params } })"
                  >
                    <dt :class="{ required: !formData.pay_method.value }">
                      <template v-if="formData.pay_method.value">
                        {{
                          parseCardName(formData.pay_method.value.card_name) +
                          '   ' +
                          formData.pay_method.value.card_number.substring(
                            formData.pay_method.value.card_number.length - 4
                          )
                        }}
                      </template>
                      <template v-else> {{ $__t('{reserve-order}.pay_method.required') }}</template>
                    </dt>
                    <dd>
                      <span class="text">
                        <template v-if="order.promotion && order.promotion.promotion_id">
                          <template v-if="order.promotion.promotion_type === 'coupon'">
                            {{ coupon && coupon.coupon_name ? coupon.coupon_name : '' }}
                          </template>
                          <template v-else-if="order.promotion.promotion_type === 'discount-code'">
                            {{ order.promotion.discount_code }}
                          </template>
                        </template>
                        <template v-else-if="formData.pay_method.value">
                          {{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}
                        </template>
                      </span>
                      <button type="button" class="icon">
                        <img src="/img/arrow-right@3x.png" class="arrow" />
                      </button>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="section price">
              <div class="section-header">
                <h2>
                  {{ $__t('{check-out-info}.payment_amount') | capitalizeFirstLetter }}
                </h2>
              </div>
              <div class="section-body">
                <div class="price-section">
                  <dl>
                    <dt class="title">
                      {{ $__t('기본요금') | capitalizeFirstLetter }}
                    </dt>
                    <dd class="description">
                      <span class="color--blue">{{ originAmount }} {{ $__t('won') }}</span>
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{ $__t('{estimated-price}.section.list.key.BASIC_FEE') | capitalizeFirstLetter }}
                    </dt>
                    <dd class="description">{{ basicPrice.toLocaleString() }} {{ $__t('won') }}</dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">{{ $__t('Additional Charge') | capitalizeFirstLetter }}</dt>
                    <dd class="description">
                      {{ additionalPrice > 0 ? '+ ' + additionalPrice.toLocaleString() : 0 }}
                      {{ $__t('won') }}
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">{{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}</dt>
                    <dd class="description">
                      {{ discountAmount > 0 ? '-' + discountAmount.toLocaleString() : 0 }} {{ $__t('won') }}
                    </dd>
                  </dl>
                  <!-- <dl class="secondary">
                    <dt class="title">{{ $__t('이용권 사용 금액') | capitalizeFirstLetter }}</dt>
                    <dd class="description">
                      {{ order.promotion.type === 'pass' ? '- ' + discountAmount : 0 }} {{ $__t('won') }}
                    </dd>
                  </dl> -->
                </div>
                <div class="price-section">
                  <dl class="main-item">
                    <dt class="title">
                      {{ $__t('{reserve-order}.reserve.payment.total') }}
                    </dt>
                    <dd class="description">
                      <span class="color--blue"
                        ><strong>{{ predictedAmount }}</strong> {{ $__t('won') }}</span
                      >
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="section footer">
              <dl class="footer-item">
                <dt class="title">
                  <img src="/img/icon/emoji-pouch.svg" />
                  <span class="title__text">{{ $__t('이용안내') }}</span>
                </dt>
                <dd class="description" v-html="$__t('{locker-reserve}.notice.guide.description')" />
              </dl>
              <dl class="footer-item">
                <dt class="title">
                  <img src="/img/icon/emoji-ticket.svg" />
                  <span class="title__text">{{ $__t('{reserve-order}.notice.expired_ticket.title') }}</span>
                </dt>
                <dd class="description" v-html="$__t('{locker-reserve}.notice.expired_ticket.description')"></dd>
              </dl>
              <div class="footer-item footer-item--transparent">
                {{ $__t('위 주문 내용을 확인 하였으며, 회원 본인은 결제에 동의합니다.') }}
              </div>
            </div>

            <div class="section condition">
              <div class="section-header">
                <h3>{{ $__t('구매조건 확인 및 결제대행 서비스 약관 동의') }}</h3>
                <button v-button type="button" class="text underline" @click="openRef('modalTerms')">
                  <span class="text">{{ $__t('보기') }}</span>
                </button>
              </div>
            </div>

            <div class="button-area">
              <button type="submit" v-button class="activated">
                {{ $__t('예약하기') }}
              </button>
            </div>
          </ui-form>
        </template>
        <template v-else>
          <div class="section">
            <div v-for="i in 10" :key="`section-${i}`" class="section-body">
              <PuSkeleton :count="3" :loading="true" height="3rem" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <template v-slot:body>
        <Condition @close="closeRef('condition-modal')" />
      </template>
    </guide-modal>

    <guide-modal id="modal-terms" ref="modalTerms" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <agree-terms :store_name="order.product_data.store_name" />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import countryCodes from '@/assets/json/country-codes.json';
import Condition from '@/components/pages/store/StoresSearchCondition';
import AgreeTerms from '@/components/includes/ReserveOrder/AgreeTerms.vue';
import utils from '@/utils';
import UiSlider from '@/components/modules/Slider/Body.vue';
import _ from 'lodash';

export default {
  name: 'ReserveOrder',

  props: ['order_id'],

  data() {
    const AUTH_DATA = this.$store.state.auth;

    return {
      reserveInfoVisible: false,

      formData: {
        user_name: {
          type: 'text',
          label: this.$__t('성명'),
          value: AUTH_DATA.user_name || AUTH_DATA.user_last_name + ' ' + AUTH_DATA.user_first_name,
          validation: [{ type: 'required' }],
          error: '',
        },
        user_dial_code: {
          value: AUTH_DATA.user_dial_code || '+82',
          error: '',
          validation: [{ type: 'required' }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t('국가 번호'),
        },
        user_phone: {
          type: 'text',
          label: this.$__t('휴대폰 번호'),
          placeholder: this.$__t('휴대폰 번호를 입력해주세요'),
          validation: [{ type: 'required' }, { type: 'number' }],
          input_mode: 'number',
          error: '',
          value: AUTH_DATA.user_phone,
          mask: '###-####-####',
        },
        user_email: {
          type: 'text',
          label: this.$__t('이메일 주소'),
          validation: [{ type: 'required' }, { type: 'email' }],
          value: AUTH_DATA.user_email,
          error: '',
        },
        coupon_code: {
          value: '',
        },
        discount_code: {
          value: '',
        },
        pay_method: {
          items: { label: this.$__t('Credit Card'), value: 'card' },
          value: this.primaryBilling,
          validation: [{ type: 'required' }],
        },
      },

      errors: [],

      listQuery: {
        payMethod: {
          page: 1,
          size: 10,
        },
      },

      orderAgreed: false,
    };
  },

  watch: {
    formData: {
      handler: _.debounce(function () {
        this.$store.commit('resourceForm/SET_ITEM', {
          data: {
            form_id: 'reserve_form',
            user_name: this.formData.user_name.value,
            user_dial_code: this.formData.user_dial_code.value,
            user_phone: this.formData.user_phone.value,
            user_email: this.formData.user_email.value,
          },
        });
      }, 100),
      deep: true,
    },
  },

  computed: {
    reserveFormState() {
      const RESERVE_FORM_STATE = this.$store.getters['resourceForm/GET_ITEM']({
        key: 'form_id',
        value: 'reserve_form',
      });
      this.formData.pay_method.value = RESERVE_FORM_STATE.pay_method;

      return RESERVE_FORM_STATE;
    },

    order() {
      return this.$store.getters['orders/GET_ITEM']({ key: 'reserve_id', value: this.order_id });
    },

    duration() {
      return this.$moment.duration(
        Math.abs(this.$moment(this.order?.reserve_date_start).diff(this.$moment(this.order?.reserve_date_end)))
      );
    },

    coupon() {
      return this.$store.getters['resourceCoupons/GET_ITEM']({
        key: 'uid',
        value: this.formData.coupon_code.value.uid,
      });
    },

    billings() {
      return this.$store.getters['billings/GET_ITEMS_BY_LIST']({ list: 'MyBillings_available' });
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    },

    submitActivated() {
      return Object.keys(this.formData).every((o) => {
        if (this.formData[o]?.validation?.some((v) => v.type === 'required')) {
          return !!this.formData[o].value;
        }

        return true;
      });
    },

    usageTimeText() {
      console.log( "this.order", this.order );

      return `<p>
        <span>보관함 ${this.order?.product_data?.locker_number} • ${this.order?.product_data?.locker_size} </span><br/>
        <span>${this.getLabelOfDates(this.order?.reserve_date_start)} ${this.$moment(this.order?.reserve_date_start).add(10, 'm').format('HH:mm')} 까지 보관 필요</span>
      </p>`;
    },

    ticketQuantityText() {
      try {
        let value = '';

        if (this.order.product_data.quantity_small > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.order.product_data.quantity_small}`;

          if (this.order.product_data.quantity_small > 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }

        if (this.order.product_data.quantity_big > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.order.product_data.quantity_big}`;

          if (this.order.product_data.quantity_big > 0) quantityString = ' ' + quantityString;

          if (this.order.product_data.quantity_big > 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }

        return value;
      } catch (e) {
        return null;
      }
    },

    predictedAmount() {
      return (this.order?.predicted_amount || 0).toLocaleString();
    },

    discountAmount() {
      return (this.order?.payment?.discount || 0).toLocaleString();
    },

    originAmount() {
      return (
        this.order?.payment?.origin?.toLocaleString() || (this.basicPrice + this.additionalPrice).toLocaleString() || 0
      );
    },

    basicPrice() {
      let price = 0;

      switch (this.order?.product_data?.locker_size) {
        case 'S':
          price = 1000;
          break;
        case 'M':
          price = 1500;
          break;
        case 'L':
          price = 2000;
          break;
        case 'XL':
          price = 3000;
          break;
      }

      return price;
    },

    additionalPrice() {
      if (this.duration) {
        let durationMinutes = this.duration.asMinutes();

        const unitPrice =
          this.order?.product_data?.locker_size === 'XL'
            ? 500
            : this.order?.product_data?.locker_size === 'L'
            ? 400
            : this.order?.product_data?.locker_size === 'M'
            ? 300
            : this.order?.product_data?.locker_size === 'S'
            ? 200
            : 0;

        const afterHalfDayPrice =
          this.order?.product_data?.locker_size === 'XL'
            ? 2500
            : this.order?.product_data?.locker_size === 'L'
            ? 2000
            : this.order?.product_data?.locker_size === 'M'
            ? 1500
            : this.order?.product_data?.locker_size === 'S'
            ? 1000
            : 0;

        let price = 0;

        if (durationMinutes <= 120) {
          price = 0;
        } else if (durationMinutes > 120 && durationMinutes <= 720) {
          price = Math.ceil((durationMinutes - 120) / 60) * unitPrice;
        } else {
          price = 10 * unitPrice + Math.ceil((durationMinutes - 720) / 720) * afterHalfDayPrice;
        }

        return price;
      }
    },
  },

  async created() {
    try {
      const orderData = await this.loadOrderData();
      if (Array.isArray(this.order?.tickets) && this.order.tickets.length > 0) {
        throw new Error(this.$__t('이미 처리된 티켓입니다.'));
      }

      console.log( "orderData", orderData );

      this.setReserveFormState(orderData);

      await this.getPayMethods();

      this.loadFormStateData();
      this.onPassCode();
    } catch (e) {
      console.error(e);
      // this.$router.replace('/');
    }
  },

  mounted() {
    this.$analytics.logEvent('mobile reservation info view');
  },

  beforeDestroy() {
    this.$store.commit('resourceForm/UPDATE_ITEM', {
      data: {
        form_id: 'reserve_form',
        reserve_info_visible: false,
      },
    });
  },

  methods: {
    getLabelOfDates(dateString) {
      let date = this.$moment(dateString);
      let weekdays = [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT'),
      ];

      let formattedDate = date.format('MM/DD');
      let day = date.day();

      if (date.format('MM/DD') === this.$moment().format('MM/DD')) {
        formattedDate = this.$__t('{date-time-picker}.today');
      } else {
        formattedDate += ' (' + weekdays[day] + ')';
      }

      return formattedDate;
    },

    parseCountryCodes(countryCodes) {
      let _dial_code = countryCodes.map((o) => ({ label: `${o.dial_code} (${o.code})`, value: o.dial_code })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    async loadOrderData() {
      try {
        return await this.$store.dispatch('orders/reserve', { id: this.order_id });
      } catch (e) {
        // this.$store.commit('alert/ADD_ITEM', { message: this.$__t(e.message), status: 'error' });
        // this.$store.commit('loading/SET_TRUE');
        // setTimeout(() => {
        //   this.$router.replace('/stores');
        //   this.$store.commit('loading/SET_FALSE');
        // }, 1000);
      }
    },

    async updateOrder(options = {}) {
      try {
        return await this.$store.dispatch('orders/update', options);
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      }
    },

    loadFormStateData() {
      let {
        user_name,
        user_dial_code,
        user_phone,
        user_email,
        coupon_code,
        discount_code,
        pay_method,
        reserve_info_visible,
      } = this.reserveFormState || {};

      try {
        this.formData.user_name.value = user_name || this.formData.user_name.value;
        this.formData.user_dial_code.value = user_dial_code || this.formData.user_dial_code.value;
        this.formData.user_phone.value = user_phone || this.formData.user_phone.value;
        this.formData.user_email.value = user_email || this.formData.user_email.value;
        this.formData.coupon_code.value = coupon_code || this.formData.coupon_code.value;
        this.formData.discount_code.value = discount_code || this.formData.discount_code.value;
        this.formData.pay_method.value = pay_method || this.primaryBilling || this.formData.pay_method.value;
        this.reserveInfoVisible = reserve_info_visible || this.reserveInfoVisible;
      } catch (e) {}
    },

    setReserveFormState(res) {
      return this.$store.commit('resourceForm/SET_ITEM', {
        data: {
          form_id: 'reserve_form',
          store_id: res.product_id,
          date_start: res.reserve_date_start,
          date_end: res.reserve_date_end,
          quantity_small: parseInt(res.reserve_quantity) || 0,
          quantity_big: parseInt(res.reserve_over_quantity) || 0,
        },
      });
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    getErrors(error) {
      this.errorArray = [];

      return error.split(',').map((errorString) => {
        if (errorString.includes('INVALID_START_DATE')) return this.$__t('It is not opening time');
        if (errorString.includes('INVALID_END_DATE')) return this.$__t('It is not opening time');
        if (errorString.includes('CLOSED_START_DATE')) return this.$__t('{error-message}.closed.temporal');
        if (errorString.includes('CLOSED_END_DATE')) return this.$__t('{error-message}.closed.temporal');
        if (errorString.includes('CLOSED')) return this.$__t('OUT_OF_STOCK');
        if (errorString.includes('OUT_OF_STOCK')) return this.$__t('OUT_OF_STOCK');
        if (errorString.includes('OUT_OF_BIGABLE_STOCK')) return this.$__t('OUT_OF_STOCK');
      });
    },

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form-list-item div.error, .required');
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    onSubmit() {
      return this.$refs.form.submit();
    },

    submitForm: _.debounce(function (values) {
      this.$store.commit('loading/SET_TRUE');
      this.$analytics.logEvent('reservation completed', {
        store_id: this.order?.product_id,
        store_name: this.order?.product_data?.store_name,
        store_location: this.order?.product_data?.store_place,
        discount_code: this.order?.promotion?.discount_code || this.formData?.discount_code?.value,
        coupon_code: this.coupon?.coupon_code,
        small: Number(this.order?.reserve_quantity || 0),
        large: Number(this.order?.reserve_over_quantity || 0),
        price: this.predictedAmount + '원',
      });

      try {
        let { pay_method, user_email, user_name, user_phone } = values;
        pay_method = utils.parseJSON(pay_method);

        const USER_DATA = {
          user_name,
          user_phone,
          user_email,
        };

        const type = this.order?.product_data?.store_type;

        let data = Object.assign(
          {
            reserve: JSON.stringify(USER_DATA),
            billing: JSON.stringify({
              billing_id: pay_method.uid,
              ...USER_DATA,
            }),
          },
          type === 'LOCKER' ? {} : { issue_type: 'DEPOSIT_ADVANCE' }
        );

        return this.$store
          .dispatch(`orders/complete`, {
            id: this.order_id,
            data,
            type: type === 'LOCKER' ? 'LOCKER' : 'LUGGAGE',
            options: { clearGroup: true },
          })
          .then((response) => {
            this.$analytics.logEvent('reservation completed confirm', {
              store_id: this.order?.product_id,
              store_name: this.order?.product_data?.store_name,
              store_location: this.order?.product_data?.store_place,
              discount_code: this.order?.promotion?.discount_code || this.formData?.discount_code?.value,
              coupon_code: this.coupon?.coupon_code,
              small: Number(this.order?.reserve_quantity || 0),
              large: Number(this.order?.reserve_over_quantity || 0),
              price: this.predictedAmount + '원',
            });
            this.$store.dispatch('auth/edit', USER_DATA);

            this.fetchTickets().finally(() =>
              this.$router.push({
                name: 'TicketSingle',
                params: {
                  id:
                    response.ticket_code ||
                    (response.tickets && Array.isArray(response.tickets)
                      ? response.tickets[0].ticket_code
                      : response.ticket?.ticket_code),
                },
              })
            );
          })
          .catch((error) => this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' }))
          .finally(() => {
            this.$store.commit('loading/SET_FALSE');
          });
      } catch (e) {
        console.error(e);
        this.$store.commit('loading/SET_FALSE');
        this.$store.commit('alert/ADD_ITEM', { message: JSON.stringify(e || {}), status: 'error' });
      }
    }, 10),

    userData() {
      return {
        pay_method: this.$store.getters['userData/GET_ITEM']({ key: 'pay_method' }),
        coupon_code: this.$store.getters['userData/GET_ITEM']({ key: 'coupon_code' }),
        discount_code: this.$store.getters['userData/GET_ITEM']({ key: 'discount_code' }),
        user_name: this.$store.getters['userData/GET_ITEM']({ key: 'user_name' }),
        user_dial_code: this.$store.getters['userData/GET_ITEM']({ key: 'user_dial_code' }),
        user_phone: this.$store.getters['userData/GET_ITEM']({ key: 'user_phone' }),
        user_email: this.$store.getters['userData/GET_ITEM']({ key: 'user_email' }),
      };
    },

    async getPayMethods() {
      try {
        await this.$store.dispatch('billings/getCardList', {
          query: this.listQuery.payMethod,
          list: 'MyBillings_available',
        });

        if (this.primaryBilling) {
          this.formData.pay_method.value = this.primaryBilling;
        }
      } catch (e) {
        console.error(e);
      }
    },

    parseCardName(cardName = '') {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(cardName);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return cardName;
    },

    async fetchTickets(options = {}) {
      try {
        this.$store.commit('tickets/CLEAR_ITEMS');
        this.$store.commit('tickets/CLEAR_ALL_LIST');
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch('tickets/getList', {
          list: 'MyTickets_all',
          filter: 'all',
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch('tickets/getList', {
          list: 'MyTickets_available',
          filter: 'available',
          query: { page: 1, size: 10 },
        });

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      }
    },

    async onPassCode() {
      this.$store.commit('loading/SET_TRUE');
      const pass_code = this.$route.query['pass-code'];
      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
      }, 1000);

      if (pass_code) {
        return await this.updateOrder({
          id: this.order_id,
          start_date: this.order.product_data.reserve_start_date_time,
          end_date: this.order.product_data.reserve_end_date_time,
          quantity: this.order.product_data.quantity_small,
          over_quantity: this.order.product_data.quantity_big,
          pass_code: pass_code,
        });
      }
    },
  },

  components: {
    Condition,
    AgreeTerms,
    UiSlider,
  },
};
</script>

<style lang="scss" scoped>
.page.reserve-locker {
  @include container('& > .container', $max-content-width);

  & > .container {
    height: 100%;
    background-color: $color-white;

    .page-header {
      padding: unit(20) unit(16);
      flex-direction: column;
      background-color: #f1f5f5;

      .title {
        font-weight: 500;
        font-size: unit(22);
        line-height: unit(32);
        color: $color-gray-9;
      }

      .secondary {
        margin-top: unit(6);
        font-size: unit(14);
        line-height: unit(20);
        color: #878d91;
      }

      .reserve-info {
        border: 1px solid #e1e4e6;
        background: #ffffff;
        border-radius: unit(20);
        margin-top: unit(20);
        margin-bottom: unit(19);

        &__content {
          padding: unit(20) unit(16);

          .content-header {
            font-weight: bold;
            font-size: unit(16);
            line-height: 1.75;
          }

          .content-body {
            margin-top: unit(4);
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.43;
            letter-spacing: -0.006em;
            color: #61676c;
          }
        }

        &__button {
          height: unit(50);
          border-top: solid 1px #e1e4e6;
          color: #878d91;
        }
      }
    }

    .page-body {
      margin-top: unit(20);
      padding: 0;
    }

    .section {
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: unit(10);
        background: #f1f5f5;
      }

      .section-header {
        display: flex;
        align-items: flex-start;
        min-height: unit(50);
        padding: unit(13) unit(16);
        border-bottom: solid 1px #eaeeef;

        h2 {
          flex: 1;
          font-weight: 500;
          font-size: unit(16);
          line-height: unit(23);
          color: $color-gray-9;
        }

        .arrow {
          flex: 0 0 2 * $unit;
          width: 2 * $unit;
          height: 2 * $unit;
        }
      }

      .section-body {
        padding: unit(20) unit(16);
      }

      &.reserve {
        padding: 0;

        .section-header {
          border-bottom: solid 1px #eaeeef;
        }

        .section-body {
          padding-bottom: unit(30);
          display: flex;
          gap: unit(40);

          .content {
            flex: 1;
            min-width: 0;

            .primary {
              font-size: unit(14);
              line-height: unit(20);
              @include ellipsis(1);
            }

            .secondary {
              padding-top: unit(8);
              font-size: unit(14);
              line-height: unit(20);
              color: #878d91;
              @include ellipsis(2);
            }
          }

          .button {
            align-self: flex-end;
            flex: 0 0 auto;

            button {
              border: 1px solid #008fff;
              border-radius: unit(30);
              padding: unit(3) unit(9);
              color: #008fff;
              line-height: unit(17);
              text-transform: uppercase;
              font-weight: 500;
            }
          }
        }
      }

      &.booker {
        border: 0;

        .ui-form-list-item {
          & + .ui-form-list-item {
            margin-top: unit(28);
          }
        }
      }

      &.pay-method {
        .section-body {
          padding-bottom: unit(30);
          min-height: unit(62);
        }

        dl {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          line-height: normal;
          gap: unit(20);

          & + dl {
            margin-top: unit(12);
          }

          dt {
            font-size: unit(14);
            line-height: unit(24);
            flex-shrink: 0;
            min-width: unit(100);

            &.required {
              color: #ff5d5d;
            }
          }

          dd {
            display: flex;
            justify-content: flex-end;
            gap: unit(2);
            min-width: unit(100);

            .icon {
              display: inline-block;
              width: unit(24);
              height: unit(24);
              vertical-align: middle;
              bottom: unit(1);
              min-width: 0;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .text {
              min-width: 0;
              font-size: unit(14);
              line-height: unit(24);
              color: #878d91;
            }
          }
        }
      }

      &.price {
        .section-header {
          display: block;
        }

        dl {
          display: flex;
          align-items: flex-start;
          line-height: normal;

          & + dl {
            margin-top: unit(12);
          }

          dt {
            flex: 1;
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
            color: #61676c;

            .title__button {
              margin: 0 unit(2);
              top: unit(4);
              width: unit(18);
              height: unit(18);
              object-fit: contain;
              display: inline-block;

              & > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          dd {
            flex: 0 0 auto;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
          }

          &.secondary {
            padding-left: unit(14);

            dt {
              color: #878d91;
              font-weight: normal;
            }
          }

          .color {
            &--blue {
              color: #008fff;
            }
          }

          strong {
            font-weight: bold;
            font-size: unit(30);
            letter-spacing: -0.04em;
            color: #008fff;
          }
        }

        .price-section {
          padding: unit(20) 0;

          &:first-of-type {
            padding-top: 0;
          }

          & + .price-section {
            border-top: solid 1px #e1e4e6;
          }
        }

        .main-item {
          display: flex;
          align-items: center;

          .title {
            font-size: unit(14);
            font-weight: 500;
            color: $color-black;
          }

          .secondary {
            font-size: unit(12);
            line-height: unit(26);
            text-align: right;
            color: #008fff;

            strong {
              font-weight: bold;
              font-size: unit(18);
              line-height: unit(26);
              color: #008fff;
            }
          }
        }
        .section-header {
          .primary {
            color: $color-gray-9;
          }

          .secondary {
            color: #008fff;
            font-size: unit(12);

            strong {
              font-weight: bold;
              font-size: unit(16);
            }
          }
        }
      }

      &.condition {
        &::after {
          display: none;
        }

        .section-header {
          display: flex;
          align-items: center;

          h3 {
            flex: 1;
            font-weight: 500;
            font-size: unit(12);
          }

          button {
            flex: 0 0 auto;
            width: auto;
            display: inline-block;
            padding: unit(3) unit(9);

            & > * {
              font-size: unit(12);
              color: #a9afb3;
              text-decoration-line: underline;
            }
          }
        }

        .section-body {
          h4 {
            font-weight: 500;
            font-size: unit(12);
            line-height: unit(17);
            color: #878d91;
            margin-bottom: unit(12);
          }

          p {
            line-height: unit(17);
            color: #878d91;

            & + p {
              margin-top: unit(16);
            }
          }
        }
      }

      &.footer {
        background-color: #f1f5f5;
        padding: unit(30) unit(16);

        &::after {
          // height: unit(70);
        }

        .footer-item {
          padding: unit(16);
          background-color: $color-white;
          border: 1px solid #e1e4e6;
          box-shadow: unit(2) unit(2) unit(20) rgba(0, 0, 0, 0.06), unit(2) unit(2) unit(10) rgba(0, 0, 0, 0.04);
          border-radius: unit(20);

          &--transparent {
            background-color: transparent;
            padding-bottom: 0;
            line-height: 1.5;
            letter-spacing: -0.02em;
            color: #878d91;
            margin-top: 0 !important;
            box-shadow: none;
            border: none;
          }

          & + .footer-item {
            margin-top: unit(20);
          }

          .title {
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.43;
            letter-spacing: -0.02em;
            display: flex;
            flex-direction: column;

            img {
              width: unit(24);
              height: unit(24);
              object-fit: contain;
              bottom: unit(1);
            }

            &__text {
              margin-top: unit(6);
            }
          }

          .description::v-deep {
            margin-top: unit(6);
            font-size: unit(14);
            line-height: 1.71;

            strong {
              font-weight: 700;
            }
          }

          & + .footer-item {
            border-top: solid 1px #eaeeef;
          }

          &:last-child {
            color: #878d91;
          }
        }
      }
    }

    .button-area {
      padding: unit(14) unit(16) unit(14) unit(16);
      background-color: $color-white;

      button {
        background: #a9afb3;
        border-radius: unit(16);
        color: $color-white;
        text-transform: uppercase;
        font-weight: 500;
        font-size: unit(15);
        padding: unit(10) unit(20);

        &.activated {
          background-color: #0099ff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
